import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Profilaktyka stomatologiczna'}>
      <Helmet title={"Profilaktyka stomatologiczna - Klinika stomatologiczna | Świdnica"} />
    </OfferBase>
  )
}

export default Offer
